import "lib/@hooks/useEvent"
import { createEvent } from "library/local-events"

// User authentication
export const UserInitialized = createEvent("UserInitialized") // User has been initialised, and we know whether they
// are authenticated or not
export const UserLoggedOut = createEvent("UserLoggedOut") // User has logged out
export const UserAuthenticated = createEvent("UserAuthenticated") // User has been authenticated

//
export const FlushGraphQLCache = createEvent("FlushGraphQLCache") // User has been authenticated

// Routes
export const RoutesGet = createEvent("Routes.Get") // Gets routes, normally this is not directly used, use
// registerRoute instead
export const Navigate = createEvent("Navigate", { isAsync: true }) // Causes a navigation to a path
export const Navigation = createEvent("Navigation") // Used to get a list of all the navigation elements
export const Navigated = createEvent("Navigated") // Raised when the system has navigated
export const RoutesChanged = createEvent("routesChanged") // Raised when the available routes have changed

// Tree events
export const SidebarTreeRoots = createEvent("SidebarTreeRoots") // Retrieve the top level elements of the tree

// Snackbars
export const ShowSnackBar = createEvent("ShowSnackbar") // Shows a snackbar, normally this is used via the snackbar()
// helper functions

// Tree
export const RefreshMainTree = createEvent("RefreshMainTree") // Raised to cause the main tree to refresh
export const TreeGoto = createEvent("TreeGoto") // Navigates the tree to a specific path
export const GroupExpanded = createEvent("GroupExpanded") // Notification that a group in the tree has been expanded
export const RedrawTreeList = createEvent("RedrawTreeList") // Raised to cause the current list of items in the tree to
// redraw

// Schedules
export const RefreshSchedules = createEvent("RefreshSchedules") // Raised when schedules views should refresh
export const FilterSchedule = createEvent("FilterSchedule") // Used to filter available schedules

// Schedule Selector
export const RefreshScheduleSelector = createEvent("RefreshScheduleSelector") // Raise to refresh
// the schedule
// selector

// When the view mode changes for a schedule between compact/expanded etc
export const ChangeView = createEvent("ChangeView")

export const RefreshScheduleItem = createEvent("RefreshScheduleItem") // Raised when a particular schedule item should
// be refreshed
export const RenderSelector = createEvent("RenderSelector") // Raised when the selector has been rendered

// Selection
export const Selection = createEvent("Selection") // Grouping
export const SelectionChange = Selection("Change") // The selection has changed, uses subEvents for the types of
// selection

// Searching
export const SearchFor = createEvent("SearchFor") // Raised when the system wants to search

// Tasks
export const SelectableChanged = createEvent("SelectableChanged")
export const GetSelectable = createEvent("GetSelectable") // Raised to find all selectable items
export const CollapseAllTasks = createEvent("CollapseAllTasks") // Raise to collapse tasks if they are expanded
export const NavigateTask = createEvent("NavigateTask") // Scroll the task into view, presuming it is in the current
// schedule
export const TaskListFilter = createEvent("TaskListFilter") // Used to filter the tasks currently shown by a schedule
export const TaskListRefresh = createEvent("TaskListRefresh")

// Updates when baskets are changed
export const BasketUpdate = createEvent("BasketUpdate") // Baskets are updated, this is a group that has type and
// basket id in the event afterwards
export const AllBasketUpdates = BasketUpdate("**") // Called with all updates to any basket
export const ScheduleBasketUpdate = BasketUpdate("ScheduleBasket") // Updates that affect ScheduleBaskets, this is a
// group with subtypes of the basket id
export const TagBasketUpdate = BasketUpdate("Tag") // Group of all updates associated with tags
export const AllTagBasketUpdates = TagBasketUpdate("*") // All updates of tags
export const AllScheduleBasketUpdates = ScheduleBasketUpdate("*") // Handles updates to any schedule basket

// Flags editability of a task item
export const CanEditTaskItem = createEvent("CanEditTaskItem")

// Icons
export const GetIcon = createEvent("GetBasketIcon") // Retrieves the icon for a group of schedules usually derived from
// baskets

// Field autofocus for editing
export const EditField = createEvent("EditField")

export const StartEditor = createEvent("StartEditor") // Used to turn other editors off when a new editor starts editing

export const SummaryDataChanged = createEvent("SummaryDataChanged") // Raise to refresh summary views when underlying
// tasks change

export const CompareSchedules = createEvent("CompareSchedules") // Handle to add comparisons to a schedule comparison
export const CompareTasks = createEvent("CompareTaks") // Handle to add comparisons for a task to a task
export const HasInvalidated = createEvent("HasInvalidated") // Raised when a query name has been invalidated
export const Invalidate = createEvent("Invalidate") // Raised when a query name needs to be invalidated
export const AcceptedChanged = createEvent("AcceptedChanged") // The accepted list of comparisons has changed
export const IsAccepted = createEvent("IsAccepted", (v) => v[0].isAccepted)
export const FlushScheduleCache = createEvent("FlushScheduleCache")

// Busy notification
export const Busy = createEvent("Busy")
export const BusyAdd = Busy("Add")
export const BusyRemoved = Busy("Remove")
export const BusyHidden = Busy("Hidden")

// Editor commit demand
export const EditEnding = createEvent("EditEnding")

// Overrides for editing items
export const OpenSchedule = createEvent("OpenSchedule")
export const OpenDraft = createEvent("OpenDraft")

// Current client has been updated
export const CurrentClientChanged = createEvent("CurrentClientChanged")
// A legislation item has been displayed (usually used internally for scrolling)
export const DisplayLegislationItem = createEvent("DisplayLegislationItem")
// Causes the left nav bar to redraw (perhaps after something might have become visible or invisible)
export const RefreshNavBar = createEvent("RefreshNavBar")
//Collapses the nav bar
export const CollapseNavBar = createEvent("CollapseNavBar")
export const CollapseSideBar = createEvent("CollapseSideBar")
// Raised to get the correct icon for a schedule folder
export const ScheduleFolderIcon = createEvent("ScheduleFolderIcon")
export const ClientTreeUpdated = createEvent("ClientTreeUpdated")
export const DragStarted = createEvent("DragStarted")
export const DragEnded = createEvent("DragEnded")
export const DragSetOverlay = createEvent("DragSetOverlay")
export const CanContainGroup = createEvent("CanContainGroup")
export const RowHeadersUpdated = createEvent("RowHeadersUpdate")
export const RenderCell = createEvent("RenderCell")
export const AddToLibrary = createEvent("AddToLibrary", (v) => v[0].library)
export const AddToCore = createEvent("AddToCore", (v) => v[0].core)
export const AddToGroup = createEvent("AddToGroup")
export const RemoveFromGroup = createEvent("RemoveFromGroup")
export const GetSortLabel = createEvent("GetSortLabel", (v) => v[0].label)
export const Validate = createEvent("Validate")
export const ShowNotification = createEvent("show-notification")
export const AppRefresh = createEvent("AppRefresh")
export const CaptureNow = createEvent("CaptureNow")
export const Drag = createEvent("Drag")
export const DragOverlayEvent = Drag("Overlay")
export const DragOver = Drag("Over")
export const DragMove = Drag("Move")
export const DragEnd = Drag("End")
export const DragCancel = Drag("Cancel")
export const RefreshTreeRoots = createEvent("RefreshTreeRoots")
export const TreeRootsUpdated = createEvent("TreeRootsUpdated")
export const AdaptSchedule = createEvent("AdaptSchedule")
export const RefreshSchedule = createEvent("RefreshSchedule")
export const RefreshStacks = createEvent("RefreshStacks")
export const ReferenceUpdated = createEvent("Reference.Updated")
export const ReferenceValueUpdated = createEvent("ReferenceValueUpdated")
export const MapTasksForTiming = createEvent("MapTasksForTiming")
export const AdjustStack = createEvent("AdjustStack")
export const GetCrumbLink = createEvent("GetCrumbLink")
export const RefreshTaskHeader = createEvent("RefreshTaskHeader")
export const HasMoved = createEvent("Moved")
export const ResetTableSlider = createEvent("ResetTableSlider")
export const Routing = createEvent("Routing")
export const RefreshRouter = createEvent("RefreshRouter")
export const AdaptItemForFacilities = createEvent("AdaptItemForFacilities")
export const UndoRedoChange = createEvent("UndoRedoChange")
export const UpdatedParam = createEvent("UpdatedParam") // Search params
export const OpenDialog = createEvent("open-dialog")
export const RemoveDialog = createEvent("remove-dialog")
export const ShowBackdrop = createEvent("ShowBackdrop")
export const BackdropClick = createEvent("BackdropClick")
export const IntrospectionUpdated = createEvent("IntrospectionUpdated")
export const IsNavItemActive = createEvent("IsNavItemActive")
export const RedrawPeriods = createEvent("RedrawPeriods")
export const Freezer = createEvent("Freezer")
export const FieldUpdate = createEvent("Field.Update") // Raised with the value and the target when a field is updated
export const ComparisonUpdated = createEvent("CopiedComparison")
export const CommitChange = createEvent("CommitChanges")
export const EditDialogClosed = createEvent("EditDialogClosed")
export const AcceptAll = createEvent("AcceptAll")
export const ScheduleHeaderWrapper = createEvent("ScheduleHeaderWrapper", (v) => v[0].wrapper)
export const CanSaveApprovedDocument = createEvent("CanSave")
export const UnlockDraft = createEvent("UnlockDraft")
export const DecorateIndex = createEvent("DecorateIndex", { extract: (v) => v[0].index })
//For specific help toggle
export const HelpTagsAvailable = createEvent("HelpTagsAvailable")
export const HelpTagsRemoved = createEvent("HelpTagsRemoved")
export const HelpCategoryUpdated = createEvent("HelpCategoryUpdated")
export const HelpCategorySaved = createEvent("HelpCategorySaved")

export const EnsureTree = createEvent("EnsureTree")

export const ExpandTreeItem = createEvent("ExpandTreeItem")

export const ModifyYupError = createEvent("ModifyYupError")

export const RegisterSystemTask = createEvent("RegisterSystemTask", { extract: (v) => v[0].tasks })

export const CanNext = createEvent("CanNext", { extract: (v) => v[0].next })

export const GetDemands = createEvent("GetDemands", { extract: (v) => v[0].demands })

export const AdoptClient = createEvent("AdoptClient")

export const InvalidLink = createEvent("InvalidLink")

export const HardNavigate = createEvent("HardNavigate")

export const TreeUpdated = createEvent("TreeUpdated")

export const TreeReloaded = createEvent("TreeReloaded")

export const RefreshPayload = createEvent("RefreshPayload")

export const SocketReconnected = createEvent("SocketReconnected")

export const ReconnectSocket = createEvent("ReconnectSocket")

export const RecycleType = createEvent("RecycleType", { extract: (v) => v[0].type })

export const AuthorizationUpdated = createEvent("AuthorizationUpdated")

export const CloseMenu = createEvent("CloseMenu")
export const PermissionsUpdated = createEvent("PermissionsUpdated")
export const SwitchUserClient = createEvent("SwitchUserClient")

export const FeatureEnabled = createEvent("FeatureEnabled", { extract: (v) => !!v[0].enabled })

export const SharingLinkUpdated = createEvent("SharingLinkUpdated")
export const Connected = createEvent("Connected")
export const ConnectionLost = createEvent("ConnectionLost")

export const AuthCode = createEvent("AuthCode")
export const OverarchingIntroductionLink = createEvent("OverarchingIntroductionLink")

export const OnBulletinEdited = createEvent("OnBulletinEdited")

export const AvailableFeatures = createEvent("AvailableFeatures", { extract: (v) => v[0].features })

export const WindowReload = createEvent("WindowReload")
export const ItemTypeLabel = createEvent("ItemTypeLabel")

export const HelpUpdated = createEvent("HelpUpdated")
export const PreventReload = createEvent("PreventReload")
export const EnforceTermsAndConditions = createEvent("EnforceTermsAndConditions")

export const AIScheduleList = createEvent("AIScheduleList")
export const AIResponse = createEvent("AIResponse")
export const Refresher = createEvent("Refresher")
export const WatchChanged = createEvent("WatchChanged")
export const Waiting = createEvent("Waiting")

export const AsyncScheduleAndGroupFilter = createEvent("AsyncScheduleAndGroupFilter", {
    isAsync: true,
    extract: (v) => v[0].schedules,
})

export const AdvancedFilterSetting = createEvent("AdvancedFilterSetting", {
    expose: "filters",
    parameters: () => ({ filters: [] }),
    extract: (v) => v[0].filters,
})

export const AdvancedFilterChanged = createEvent("AdvancedFilterChanged")

export const RegimeAccessChanged = createEvent("RegimeAccessChanged")

export const DecorateTreeIndex = createEvent("DecorateTreeIndex")

export const GetDynamicComponents = createEvent("GetDynamicComponents")

export const ResetTabledRepeat = createEvent("ResetTabledRepeat")

export const XmlTaskContentTypes = createEvent("XmlTaskContentTypes")
export const XmlGroupTypes = createEvent("XmlGroupTypes")

export const ServerOnline = createEvent("ServerOnline")
export const RefreshSlot = createEvent("RefreshSlot")
export const ProcessVariable = createEvent("ProcessVariable")
export const RegisterMapping = createEvent("RegisterMapping")
export const BusySystem = createEvent("BusySystem")

export const DataExportTypes = createEvent("DataExportTypes")
